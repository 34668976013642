<script>
import Swal from "sweetalert2";
import _ from "lodash";
// TODO: path get auto increment que es el que existe
// Todo: path get por ultimo directorio

export default {
  props: {
    fileTypes: {
      type: Array,
      default: () => ["image/jpeg", "image/png"], // Tipos MIME por defecto
    },
    urlDir: {
      type: String,
      default: () => "",
      required: true,
    },
    injectData: {
      type: Object,
      default: () => {},
    },
    pathPost: {
      type: String,
      required: true,
    },
    pathGet: {
      type: String,
      required: true,
    },
    autoIncrement: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      file: null,
      name: null,
      imageUrl: null,
      typeFile: null,
      loader: false,
    };
  },
  computed: {
    backgroundStyle() {
      if (this.imageUrl) {
        return {
          backgroundImage: `url("${this.imageUrl}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        };
      }
      return {};
    },
    fileBackground() {
      if (this.file) {
        let color = "#52ba99";
        // color = this.fileTypes.includes("application/pdf") ? '#e5322d' : color
        let backgroundImage = this.imageUrl ? `url("${this.imageUrl}")` : "";

        return {
          backgroundColor: color,
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        };
      }
      return {};
    },
    extensionOfFileTypes() {
      return this.fileTypes.map((elment) => {
        return elment.substring(elment.lastIndexOf("/") + 1);
      });
    },
  },
  methods: {
    quitarTildesYEspacios(input) {
      let sinTildes = _.deburr(input);
      let sinEspacios = sinTildes.replace(/\s+/g, "");
      return sinEspacios;
    },
    restaurar() {
      this.file = null;
      this.imageUrl = null;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    handleDrop(event) {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile) {
        this.validateAndProcessFile(selectedFile);
      }
    },
    validateAndProcessFile(selectedFile) {
      console.log(selectedFile.type);
      if (
        this.fileTypes.includes(selectedFile.type) &&
        selectedFile.name.split(".").length <= 2
      ) {
        this.file = selectedFile;
        this.typeFile = this.file.name.substring(
          this.file.name.lastIndexOf(".")
        );
        this.name = this.file.name;
        //Coloca de fondo la imagen siempre y cuando sea una imagen
        if (selectedFile.type.startsWith("image/")) {
          this.imageUrl = URL.createObjectURL(selectedFile);
          console.log("selectedFile", selectedFile);
        }
      } else {
        console.error("Tipo de archivo no válido.");

        if (!this.fileTypes.includes(selectedFile.type)) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Tipo de archivo no válido.",
            confirmButtonText: "Aceptar",
          });
        }

        if (selectedFile.name.split(".").length > 2) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "El nombre del archivo no puede contener puntos.",
            confirmButtonText: "Aceptar",
          });
        }

        this.file = null;
        this.imageUrl = null;
      }
      this.$refs.fileInput.value = "";
    },
    async onUpload() {
      try {
        //Consultar la base de datos la ultima imagen
        let count = 0;
        let dataGet;
        this.loader = true;

        try {
          const responseGet = await this.$store.getters.fetchGet({
            path: this.pathGet,
          });
          dataGet = await responseGet.json();
          count = dataGet.length.toString().padStart(4, "0");
        } catch {
          count = [].length.toString().padStart(4, "0");
        }

        const newPath = this.quitarTildesYEspacios(this.urlDir);
        // console.log("newPath", newPath);

        // let url = "users|pesv|evaluaciones|" + this.usuario.empresa.id + "|" +  + item.Id;
        const lastDirectory = newPath.substring(newPath.lastIndexOf("/") + 1);
        const filename = `${lastDirectory}_${count}${this.typeFile}`;
        const urlImg = newPath.split("/").join("|");

        const fileModel = {
          filename: filename,
          file: this.file,
          url: ".|site1|" + urlImg,
        };

        // console.log("fileMode", fileModel);
        // let isSave = false;
        let isSave = await this.$store.dispatch("onDropFile", fileModel);
        // console.log("Save in smarter", isSave);

        //Agregar el archivo al backend, si uploadImg es true
        //La URL en la base de datos debe comenzar "https://www.helexiumfiles.com/users/pesv/evaluaciones/.../.../*.jpg"

        if (isSave == true) {
          const url =
            "https://www.helexiumfiles.com/" + newPath + "/" + filename;

          const data = {
            ...this.injectData,
            descripcion: filename,
            urlDir: newPath,
            urlImg: url,
          };
          // console.log("data", data);

          if (this.autoIncrement || dataGet.length == 0) {
            this.$store.getters
              .fetchPost({ path: this.pathPost, data: data })
              .then((response) => {
                // console.log("response", response);
                if (response.ok) {
                  return response.json();
                }
                throw new Error("Error en el backend o DB");
              })
              .then((result) => {
                Swal.fire({
                  icon: "success",
                  title: "Se ha guardado el documento",
                  text: `El documento "${this.name}" ha sido cargado`,
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  this.restaurar();
                });

                this.$emit("onUpload", result);
              })
              .catch((error) => {
                throw new Error(error);
              });
          } else {
            data["id"] = dataGet[0].id;

            this.$store.getters
              .fetchPut({ path: `${this.pathPost}/${data.id}`, data: data })
              .then((response) => {
                // console.log("response", response);
                if (response.ok) {
                  return response.json();
                }
                throw new Error("Error en el backend o DB");
              })
              .then((result) => {
                Swal.fire({
                  icon: "success",
                  title: "Se ha guardado el documento",
                  text: `El documento "${this.name}" ha sido cargado`,
                  confirmButtonText: "Aceptar",
                }).then(() => {
                  this.restaurar();
                });

                this.$emit("onUpload", result);
              })
              .catch((error) => {
                throw new Error(error);
              });
          }
        } else {
          throw new Error(
            "Error al guardar el archivo en el servidor. Prueba nuevamente de nuevo"
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error,
          confirmButtonText: "Aceptar",
        });
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>

<template>
  <form @submit.prevent="onUpload">
    <slot name="header"> </slot>
    <input
      type="file"
      @change="handleFileChange"
      ref="fileInput"
      style="display: none"
    />
    <div
      class="dropzone"
      :style="fileBackground"
      @click="triggerFileInput"
      @drop.prevent="handleDrop"
      @dragover.prevent
      @dragenter.prevent
    >
      <div v-if="loader" class="loader"></div>
      <div v-if="!file" class="text-center">
        Haz clic o arrastra un archivo aquí. <br />
        De tipo {{ extensionOfFileTypes }}
      </div>
      <div v-else>El documento "{{ name }}" ha sido cargado</div>
    </div>
    <slot name="prevFooter"> </slot>
    <div class="actions" v-if="file">
      <button
        type="submit"
        class="btn btn-outline-success d-flex justify-content-center mx-1"
        style="min-width: 75px"
        :disabled="loader"
      >
        <Transition>
          <span v-if="loader">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else>
            <slot name="nameSuccess"> Enviar </slot>
          </span>
        </Transition>
      </button>
      <button
        class="btn btn-outline-danger mx-1"
        @click.prevent="restaurar"
        :disabled="loader"
      >
        Cancelar
      </button>
    </div>
  </form>
</template>

<style scoped>
form {
  width: 100%;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropzone {
  width: 100%;
  min-height: 150px;
  height: 80%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.dropzone:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
